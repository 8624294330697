import React, { useState } from "react";
import { Formik } from "formik";
import axios from "axios";
import { graphql } from "gatsby";

const Contact = ({ apiUrl }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  return (
    <section className="page-section bg-light" id="contact">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Contact Me</h2>
          <h3 className="section-subheading text-muted">
            You can reach us to discuss about your project and if you are
            looking for freelancer for your project
          </h3>
        </div>
        <Formik
          initialValues={{ name: "", email: "", phone: "", message: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Email field is required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            if (!values.name) {
              errors.name = "Name field is required";
            }
            if (!values.message) {
              errors.message = "Message field is required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            let apidata = {
              toEmail: "singhanku658@gmail.com",
              name: values.name,
              fromemail: values.email,
              phone: values.phone,
              subject: "Contact email CrewCode Solution",
              comment: values.message,
            };

            axios
              .post(`${apiUrl}/contact`, apidata)
              .then(function (response) {
                if (response.data.success) {
                  setSuccess(true);
                  setTimeout(() => {
                    setSuccess(false);
                    setSubmitting(false);
                  }, 3000);
                } else {
                  setError(true);
                  setTimeout(() => {
                    setError(false);
                    setSubmitting(false);
                  }, 3000);
                }
              })
              .catch(function (error) {
                console.log("Error", error);
                setError(true);
                setTimeout(() => {
                  setError(false);
                  setSubmitting(false);
                }, 3000);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form id="contactForm" onSubmit={handleSubmit}>
              <div className="row align-items-stretch mb-5">
                <div className="col-md-6">
                  <div className="form-group">
                    {/* Name input*/}
                    <input
                      className="form-control"
                      autoComplete="off"
                      id="name"
                      type="text"
                      placeholder="Your Name *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {errors.name && (
                      <div className="invalid-feedback">{errors.name}</div>
                    )}
                  </div>
                  <div className="form-group">
                    {/* Name input*/}
                    <input
                      className="form-control"
                      autoComplete="off"
                      id="email"
                      type="text"
                      placeholder="Your Email *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {errors.email && (
                      <div className="invalid-feedback">{errors.email}</div>
                    )}
                  </div>
                  <div className="form-group mb-md-0">
                    <input
                      className="form-control"
                      autoComplete="off"
                      id="phone"
                      type="tel"
                      placeholder="Your Phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group form-group-textarea mb-md-0">
                    {/* Message input*/}
                    <textarea
                      className="form-control"
                      autoComplete="off"
                      id="message"
                      placeholder="Your Message *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                    />
                    {errors.message && (
                      <div className="invalid-feedback">{errors.message}</div>
                    )}
                  </div>
                </div>
              </div>
              {success ? (
                <div id="submitSuccessMessage">
                  <div className="text-center text-success mb-3">
                    <div className="fw-bolder">
                      Thanks for contacting us, we will get back to you soon
                      hope you have filled all the information correct
                    </div>
                  </div>
                </div>
              ) : null}
              {error ? (
                <div id="submitErrorMessage">
                  <div className="text-center text-danger mb-3">
                    We are facing some issue at this moment, please try again
                    sometime
                  </div>
                </div>
              ) : null}
              <div className="text-center">
                <button
                  className="btn btn-primary btn-xl text-uppercase"
                  id="submitButton"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Send Message
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default Contact;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        apiUrl
      }
    }
  }
`;
